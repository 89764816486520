import "./index.css";

function Recall() {
  const targetLink =
    "https://play.google.com/store/apps/details?id=com.kly.meboo&pli=1";

  return (
    <div className="recall">
      <div className="content">
        <div className="middle">
          {/* 渐变部分 */}
          <div className="title">
            <div>Hey, how have you been?</div>
            {/* <div className="mouth">
              <img src="/source/mouth.png" />
            </div> */}
          </div>

          <div className="text">
            It's been a while since you last logged in, and guess what? Meboo
            has handpicked five sweethearts who are totally into you 🍆🍌. These
            gorgeous ladies are all online, eagerly waiting for you to join them
            🍑. Meboo thinks it's high time you give each other a chance, hop
            online, and flirt with them to explore those deep desires within 👅.
            <br />
            <br />
            Let desire wander between the lines, and let passion erupt anytime,
            anywhere!
          </div>

          <div className="info">
            <div className="heart">
              <img src="/source/heart.png" />
            </div>
            <a href={targetLink} target="_blank" rel="noopener noreferrer">
              <img src="/source/selfie.png" className="info-img" />
            </a>
            <div className="introduce">
              <div className="name">Olivia · 28</div>
              <div className="interest">
                From vibrant party scenes to framing artistic expressions,
                capturing life's colorful spectrum in frames.
              </div>
              {/* 按钮 */}
              <a href={targetLink} target="_blank" class="custom-button-info">
                Contact me
              </a>
            </div>
          </div>

          <div className="private">
            <div className="demon">
              <img src="/source/demon.png" />
            </div>
            <div className="pri-title">Private Photos</div>
            <div className="pri-photos">
              <div className="photo">
                <a href={targetLink} target="_blank" rel="noopener noreferrer">
                  <img src="/source/private1.png" className="pri-img" />
                </a>
                <a
                  href={targetLink}
                  target="_blank"
                  className="custom-button pri-but"
                >
                  Click me
                </a>
              </div>

              <div className="photo">
                <a href={targetLink} target="_blank" rel="noopener noreferrer">
                  <img src="/source/private2.png" className="pri-img" />
                </a>
                <a
                  href={targetLink}
                  target="_blank"
                  className="custom-button pri-but"
                >
                  Click me
                </a>
              </div>

              <div className="photo">
                <a href={targetLink} target="_blank" rel="noopener noreferrer">
                  <img src="/source/private3.png" className="pri-img" />
                </a>
                <a
                  href={targetLink}
                  target="_blank"
                  className="custom-button pri-but"
                >
                  Click me
                </a>
              </div>
            </div>
          </div>

          <div className="only">Release your desires，only on Meboo!</div>

          <div className="public">
            <div className="pub-photos">
              <a href={targetLink} target="_blank" rel="noopener noreferrer">
                <img src="/source/public1.png" className="pub-img" />
                <a href={targetLink} target="_blank" class="custom-button">
                  Contact me
                </a>
              </a>
              <a href={targetLink} target="_blank" rel="noopener noreferrer">
                <img src="/source/public2.png" className="pub-img" />
                <a href={targetLink} target="_blank" class="custom-button">
                  Contact me
                </a>
              </a>
              <a href={targetLink} target="_blank" rel="noopener noreferrer">
                <img src="/source/public3.png" className="pub-img" />
                <a href={targetLink} target="_blank" class="custom-button">
                  Contact me
                </a>
              </a>
              <a href={targetLink} target="_blank" rel="noopener noreferrer">
                <img src="/source/public4.png" className="pub-img" />
                <a href={targetLink} target="_blank" class="custom-button">
                  Contact me
                </a>
              </a>
            </div>
          </div>

          <div className="flirt">
            <div className="colorful">
              <img src="/source/colorful.png" />
            </div>
            <div className="flirt-text">Flirt online，Release your desires</div>
            <a href={targetLink} target="_blank" rel="noopener noreferrer">
              <img src="/source/goole.png" className="goole-img" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Recall;
