import { Input, Form, Button, message, Modal, Spin, Select, Space } from "antd";
import React, { useEffect, useState } from "react";
import "./App.css";

const { Option } = Select;
const Delete = () => {
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState("email");
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // if (!!values.phone && values?.phone?.length != 10) {
    //   return;
    // }
    // if(values.email.indexOf('@') == -1){
    //   message.error('Please enter the correct email address')
    //   return
    // }
    Modal.confirm({
      title: "Confirm?",
      content: "",
      onOk: () => {
        del();
      },
    });
  };

  async function del() {
    const value = await form.validateFields();
    console.log(value, "value");
    let values;
    if (type == "email") {
      values = {
        email: value.email,
      };
    } else {
      values = {
        mobile: value.mobile,
      };
    }
    setLoading(true);
    fetch("https://api.meboo-app.com/api/users/removeByEmail", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...values,
      }),
    })
      .then(() => {
        message.success("Your account has beensuccessfully deleted");
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const validator = (rule, value) => {
    if (value?.length == 0 || value?.length == 10) return Promise.resolve();
    if (value?.length !== 10) {
      return Promise.reject("Please enter a valid phone number");
    }
    return Promise.resolve();
  };
  return (
    <div className="page_body">
      {/* <div className="goback">返回</div> */}
      <a
                href="/"
                target="_blank"
                className="goback"
              >Homepage</a>
      <div
        className="slogan"
        style={{
          position: "relative",
          flexDirection: "column",
          zIndex: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="meboo"></div>
        <div className="ifyou">
          If you wish to delete your account and all related data, please follow
          these steps:
          <br />
          1. Verify your login email on meboo.
          <br />
          2. Enter your email.
          <br />
          3. click button "Confirm Account Deletion".
          <br />
          Note: Once the deletion is successful, your account will be
          deactivated, and all your data will be removed after 3 months.
        </div>
      </div>

      <div className="google_login">
        <Spin spinning={loading}>
          <div>
            <div>
              <Form
                name="basic"
                form={form}
                style={{ width: 350 }}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 20 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  name="type"
                  label="type"
                  rules={[{ required: false, message: "Please select type!" }]}
                >
                  <Select
                    placeholder="select type"
                    defaultValue={"email"}
                    onChange={(e) => {
                      setType(e);
                    }}
                  >
                    <Option value="mobile">Phone</Option>
                    <Option value="email">Email</Option>
                  </Select>
                </Form.Item>

                {type == "mobile" && (
                  <Form.Item
                    label="Mobile"
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        // message: "Please input your mobile !",
                      },
                      // { validator: validator },
                    ]}
                  >
                    <Input allowClear />
                    {/* <Space.Compact>
                      <Input style={{ width: "20%" }} defaultValue="+1" />
                      <Input style={{ width: "80%" }} maxLength={10} />
                    </Space.Compact> */}
                  </Form.Item>
                )}

                {type == "email" && (
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Please input your email !",
                      },
                    ]}
                  >
                    <Input allowClear />
                  </Form.Item>
                )}

                <Form.Item wrapperCol={{ span: 24 }}>
                  <Button htmlType="submit" danger>
                    Confirm Account Deletion
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Delete;
