import { Routes, Route } from "react-router-dom";
import { Carousel, Popover } from "antd";
import React, { useState } from "react";
import "./App.css";
import Remove from "./remove";
import Recall from "./recall";
import Qa from "./qa";
import Delete from "./delete";
const Index = () => {
  const [index, setIndex] = useState(0);
  const onChange = (currentSlide, next) => {
    setIndex(next);
  };
  return (
    <div className="App">
      <div
        className="bg"
        style={{
          backgroundImage: `url(./banner/BG_0${index + 1}.jpg)`,
        }}
      >
        
        <div className="context">
          {/* <div className='logo'></div> */}
         
          <div className="slogan">
            <div className="meboo"></div>
            <div>Chat on Meboo,</div>
            <div>date in life</div>
          </div>
          <div className="download">
            <a
              href="https://play.google.com/store/apps/details?id=com.kly.meboo&pli=1"
              target="_blank"
            >
              <img src="./googleplay.png" onClick={() => {}} />
            </a>

            <a>
            <img src="./appstore.png" onClick={() => {}} />
            </a>
          </div>
          <div className="w">
            <div className="option">
              <a
                href="./terms.html"
                target="_blank"
                className="option_item"
              >
                <img src="./Terms.png" />
                Terms
              </a>
              <Popover content={"contact@meboo-app.com"}>
                <div className="option_item">
                  <img src="./Help.png" />
                  Help
                </div>
              </Popover>
              <a
                // href="./Meboo-Privacy-Policy1.0.html"PrivacyPolicy
                href="./PrivacyPolicy.html"
                target="_blank"
                className="option_item"
              >
                <img src="./PrivacyPolicy.png" />
                PrivacyPolicy
              </a>
              <a
                href="./removeAccount"
                target="_blank"
                 className="option_item"
              >Account deletion</a>
            </div>
            <div className="option">@2023 www.meboo-app.com</div>
          </div>
        </div>
      </div>
      <Carousel
        beforeChange={onChange}
        className="carousel"
        autoplay
        autoplaySpeed={5000}
        easing="ease"
      >
        {[...new Array(5).keys()].map((_, index) => (
          <div key={index + "key"}>
            <div
              className="banner"
              style={{
                backgroundImage: `url(./banner/BG_0${index + 1}.jpg)`,
              }}
            ></div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};
const App = () => {
  return (
    <Routes basename="/">
      <Route path="/" element={<Index />} />
      {/* <Route path="/remove" element={<Remove />} /> */}
      <Route path="/recall" element={<Recall />} />
      <Route path="/qa" element={<Qa />} />
      {/* <Route path="/deleteAccount.html" element={<Delete />} /> */}
      <Route path="/removeAccount" element={<Delete />} />
    </Routes>
  );
};

export default App;
