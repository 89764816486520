import { useState, useEffect } from 'react';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import './index.scss';

function Qa() {
  const [data, setData] = useState([]);
  const [expandedItem, setExpandedItem] = useState(null);
  useEffect(() => {
    //生产：https://sc2-api.imeboo.com
    //测试：http://120.26.196.82:9104/api/system/qa/list?appCode=sparksy
    fetch('https://api.meboo-app.com/api/system/qa/list?appCode=sparksy', {
      method: 'GET',
      headers: {}
    })
      .then(response => response.json())
      .then(res => {
        if (res.code == 0) {
          setData(res.data) //res.data
        }
      })
      .catch(error => {
        // 处理错误
        console.log('error:', error)
      });
  }, []);
  const handleTitleClick = (index) => {
    setExpandedItem((prev) => (prev === index ? null : index));
  };

  const modules = {
    toolbar: [],
  };
  return (
    <div className="Qa">
      {data.length == 0 ?
        <div className='nodata'>
          <div className='nodata1'>
            <img src={'/source/nodata.png'} width={252} />
            <div className='coming'>Coming soon...</div>
          </div>
        </div> :
        <div className="source">
          {data?.map((item, index) => (
            <div key={index} className="item">
              <div className="title" onClick={() => handleTitleClick(index)}>
                <div>{item.title} </div>
                <div>{expandedItem === index ? <img src={'/source/upward.png'} style={{width:'0.6rem'}}/> : <img src={'/source/down.png'} style={{width:'0.6rem'}}/>}</div>
              </div>

              {/* {expandedItem === index && <div className="content" dangerouslySetInnerHTML={{ __html: item.content }}></div>} */}
              {expandedItem === index && (
                <div className="content">
                  <ReactQuill
                    // ref={quillRef}
                    className='modal-quill'
                    readOnly={true}
                    theme="snow"
                    modules={modules}
                    value={item.content}
                    formats={[
                      "header",
                      "font",
                      "size",
                      "bold",
                      "italic",
                      "underline",
                      "strike",
                      "blockquote",
                      "list",
                      "bullet",
                      "indent",
                      "link",
                      "image",
                      "align",
                      "color",
                      "code",
                      "background",
                    ]}

                  />
                  <div style={{ clear: 'both' }}></div>
                  {/* Use clear:both after floated content */}
                </div>
              )}

            </div>
          ))}
        </div>}
    </div>
  );
}

export default Qa;
